<template>
  <v-card>
    <v-card-title>Datos de la Contratación</v-card-title>
    <v-container v-if="error">
      <v-row>
        <v-col>
          <v-alert dense type="error">{{ error }}</v-alert>
        </v-col>
      </v-row>
    </v-container>
    <v-form v-model="formValidity" ref="form" @submit.prevent="">
      <v-container>
        <v-row>
          <v-col cols="12" md="5">
            <EmpleadoAutocomplete
              ref="empleadoAutoComplete"
              :empleadoGuardado="empleado"
            ></EmpleadoAutocomplete>
          </v-col>
          <v-col cols="12" md="2">
            <v-select
              :items="tipos"
              item-text="nombre"
              item-value="id"
              label="Tipo Contratación"
              v-model="tipo"
              :rules="selectRules"
              required
            ></v-select>
          </v-col>
          <v-col cols="12" md="2">
            <v-menu
              ref="menuFechaDesde"
              v-model="menuFechaDesde"
              :close-on-content-click="false"
              :return-value.sync="fechaDesde"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="fechaDesde"
                  label="Desde"
                  prepend-icon="mdi-calendar"
                  readonly
                  :rules="[(v) => !!v || 'Debe ingresar fecha desde']"
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="fechaDesde"
                no-title
                scrollable
                locale="es"
                required
              >
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="menuFechaDesde = false">
                  Cancelar
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.menuFechaDesde.save(fechaDesde)"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" md="2">
            <v-menu
              ref="menuFechaHasta"
              v-model="menuFechaHasta"
              :close-on-content-click="false"
              :return-value.sync="fechaHasta"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="fechaHasta"
                  label="Hasta"
                  prepend-icon="mdi-calendar"
                  readonly
                  :rules="fechaHastaRules"
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="fechaHasta"
                no-title
                scrollable
                locale="es"
              >
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="menuFechaHasta = false">
                  Cancelar
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.menuFechaHasta.save(fechaHasta)"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>
        </v-row>

        <v-divider> </v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            :disabled="!formValidity"
            color="primary"
            type="submit"
            :loading="loadingSave"
            @click="onSubmit()"
            ><v-icon left dark> mdi-check </v-icon>Guardar</v-btn
          >
          <v-btn color="error" to="/Contrataciones"
            ><v-icon left dark> mdi-close </v-icon>Cancelar</v-btn
          >
        </v-card-actions>
      </v-container>
    </v-form>
  </v-card>
</template>

<script>
import PresentismoService from "../services/PresentismoService";
import EmpleadoAutocomplete from "../components/autocompletes/EmpleadoAutocomplete.vue";
import Helpers from "../../../ControlTss-Reloj-Web/src/helpers/helpers.js";
import moment from "moment";

export default {
  name: "ContratacionForm",
  components: {
    EmpleadoAutocomplete,
  },

  data() {
    return {
      tipos: [],
      tipo: "1",
      formValidity: false,
      error: false,
      fechaDesde: null,
      fechaHasta: null,
      menuFechaDesde: false,
      menuFechaHasta: false,
      empleado: null,
      loadingSave: false,
      selectRules: [(v) => !!v || "Debe seleccionar una opción"],
      fechaHastaRules: [
        (v) =>
          moment(v).format("YYYY-MM-DD") >=
            moment(this.fechaDesde).format("YYYY-MM-DD") ||
          'La fecha "Hasta" debe ser igual o mayor a la fecha "Desde"',
      ],
    };
  },
  props: {
    contratacion: {
      type: Object,
      required: false,
    },
  },
  created() {
    PresentismoService.getTiposContratacion()
      .then((response) => {
        this.tipos = response.data.results;
      })
      .catch((error) => console.log(error));

    if (this.contratacion) {
      this.tipo = this.contratacion.tipo.id;
      this.empleado = this.contratacion.empleado;
      this.fechaDesde = this.contratacion.desde;
      this.fechaHasta = this.contratacion.hasta;
    }
  },

  methods: {
    onSubmit() {
      this.loadingSave = true;

      let contratacionInstance = {
        tipo: this.tipo,
        empleado: this.contratacion
          ? this.$refs.empleadoAutoComplete.selEmpleado.id
          : this.$refs.empleadoAutoComplete.selEmpleado,
        desde: this.fechaDesde,
        hasta: this.fechaHasta,
      };
      console.log(contratacionInstance);

      if (this.contratacion) {
        contratacionInstance.id = this.contratacion.id;
        PresentismoService.putContratacion(contratacionInstance)
          .then((response) => {
            this.$router.push({
              name: "EmpleadoDetalle",
              params: { id: contratacionInstance.id },
            });
          })
          .catch((error) => {
            this.error = Helpers.handleError(error);
            this.loadingSave = false;
          });
      } else {
        PresentismoService.postContratacion(contratacionInstance)
          .then((response) => {
            this.$router.push({
              name: "Contrataciones",
            });
          })
          .catch((error) => {
            this.error = Helpers.handleError(error);
            this.loadingSave = false;
          });
      }
    },

    validate() {
      this.$refs.form.validate();
    },
  },
};
</script>
<style scoped>
.text-upper input {
  text-transform: uppercase;
}

.camera-viewport {
  position: absolute;

  /* left: -100px; */
  top: 0px;
  height: 140%;
  width: 140%;
}

.photo-viewport {
  position: absolute;
  top: 0px;
  width: 140%;
}
</style>
<style>
.overlay {
  position: absolute;
  left: 100%;
  transform: translateX(-96%) !important;
  height: 100%;
  width: auto;
  display: flex;
}
</style>
