<template>
  <v-container>
    <v-row>
      <v-col>
        <span class="text-h4">Nueva Contratación</span>
      </v-col>
    </v-row>
    <v-divider> </v-divider>
    <br />
    <div>
      <ContratacionForm></ContratacionForm>
    </div>
  </v-container>
</template>
<script>
import ContratacionForm from "../components/ContratacionForm.vue";

export default {
  name: "ContratacionAlta",
  components: {
    ContratacionForm,
  },
  data() {
    return {
      ingresos: null,
      datosDni: {},
    };
  },
  created() {},
};
</script>
